import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { DatePicker } from 'reactstrap-date-picker';
import LocationPicker from 'react-leaflet-location-picker';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { useEvent } from './../../../hooks/useEvent';
import Loading from 'react-loading';
import { TileLayer, Marker, Popup, MapContainer, ImageOverlay, useMap } from 'react-leaflet';
import { Map } from './../map';
import 'react-datepicker/dist/react-datepicker.css';
import { createGlobalStyle } from 'styled-components';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('ptBR', ptBR);
const DefaultLocation = { lat: -0.04268524821259019, lng: -51.108055437415 };
const DefaultZoom = 18;
interface PropsEvent {
  isOpen: boolean;
  handleIsOpen: () => void;
}
const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker.full-width {
        width: 100%;
    }
    .react-datepicker-popper {
      z-index: 9999 !important;
  }
`;
export function ModalAddEvent({ isOpen, handleIsOpen }: PropsEvent): JSX.Element {
  const [date, setDate] = useState<Date | null>();
  const [title, setTitle] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [file, setFile] = useState<File>();
  const [scheduleDate, setScheduleDate] = useState('');
  const [scheduleDateEnd, setScheduleDateEnd] = useState('');
  const [sameDay, setSameDay] = useState(false);
  const [description, setDescription] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [locationTitle, setLocationTitle] = useState('');

  const { create, loading } = useEvent();

  // errors
  const [errorTitle, setErrorTitle] = useState('');
  const [errorCategoryId, setErrorCategoryId] = useState('');
  const [errorFile, setErrorFile] = useState('');
  const [errorScheduleDate, setErrorScheduleDate] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const [errorPrecisionLocation, setErrorPrecisionLocation] = useState('');
  const [errorStartTime, setErrorStartTime] = useState('');
  const [errorEndTime, setErrorEndTime] = useState('');
  const [errorLocationTitle, setErrorLocationTitle] = useState('');
  const [errorScheduleDateEnd, setErrorScheduleDateEnd] = useState('');

  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [arrayCategories, setArrayCategories] = useState([]);

  function handleChangeLocation(lat: number, lng: number): void {
    setLocation({ lat, lng });
  }

  function handleChangeZoom(newZoom: number): void {
    setZoom(newZoom);
  }

  useEffect(() => {
    getCategories()
      .then(() => {})
      .catch(() => {});
  }, []);

  async function getCategories(): Promise<void> {
    const response = await api.get('schedule-category');
    const aux = response.data;
    const array = aux.unshift({ name: 'Escolha uma opção' });
    setArrayCategories(response.data);
  }

  const pointVals = [[defaultLocation.lat, defaultLocation.lng]];
  const pointMode = {
    banner: false,
    control: {
      values: pointVals,
      onClick: (point) => {
        setDefaultLocation({ lat: point[0], lng: point[1] });
        setLatitude(point[0]);
        setLongitude(point[1]);
        setLocation(point);
        console.log("I've just been clicked on the map!", point);
      },
      onRemove: (point) => {
        console.log("I've just been clicked for removal :(", point);
      },
    },
  };
  const circleMode = {
    banner: true,
  };

  const teste = {
    center: [defaultLocation.lat, defaultLocation.lng] as unknown as [number, number],
    zoom: 16,
  };

  async function registerEvent(): Promise<void> {
    let anotherDay = '';
    if (title.length === 0) {
      setErrorTitle('Campo obrigatório.');
    } else {
      setErrorTitle('');
    }

    if (scheduleDate?.length === 0 || scheduleDate === null) {
      setErrorScheduleDate('Campo obrigatório.');
    } else {
      setErrorScheduleDate('');
    }

    if ((scheduleDateEnd?.length === 0 || scheduleDateEnd === null) && !sameDay) {
      setErrorScheduleDateEnd('Campo obrigatório.');
    } else {
      setErrorScheduleDateEnd('');
    }
    if (categoryId === 'Escolha uma opção' || categoryId === '') {
      setErrorCategoryId('Campo obrigatório.');
    } else {
      setErrorCategoryId('');
    }

    // if (file === undefined) {
    //   setErrorFile('Campo obrigatório.');
    // } else {
    //   setErrorFile('');
    // }
    if (startTime.length === 0) {
      setErrorStartTime('Campo obrigatório.');
    } else {
      setErrorStartTime('');
    }

    if (endTime.length === 0) {
      setErrorEndTime('Campo obrigatório.');
    } else {
      setErrorEndTime('');
      if (startTime > endTime) {
        if (endTime < '05:59') {
          anotherDay = 'real';
          setErrorEndTime('');
        } else {
          anotherDay = '';
          setErrorEndTime('O horário final deve ser maior que o horário inicial.');
        }
      } else {
        anotherDay = '';
        setErrorEndTime('');
      }
    }

    if (description.length === 0) {
      setErrorDescription('Campo obrigatório.');
    } else {
      setErrorDescription('');
    }
    if (locationTitle.length === 0) {
      setErrorLocationTitle('Campo obrigatório.');
    } else {
      setErrorLocationTitle('');
    }
    if (latitude.length === 0 || longitude.length === 0) {
      setErrorPrecisionLocation('Campo obrigatório.');
    } else {
      setErrorPrecisionLocation('');
    }

    if (
      title.length > 1 &&
      description.length > 1 &&
      (categoryId !== 'Escolha uma opção' || categoryId.length > 1) &&
      locationTitle.length > 1 &&
      startTime.length > 1 &&
      // file !== undefined &&
      endTime.length > 1 &&
      latitude.length !== 0 &&
      longitude.length !== 0 &&
      scheduleDate.length > 1 &&
      (startTime < endTime || endTime < '05:59')
    ) {
      const todayStart = new Date(scheduleDate);
      let todayEnd = new Date();
      if (!sameDay) {
        todayEnd = new Date(scheduleDateEnd);
      } else {
        todayEnd = new Date(scheduleDate);
      }

      if (anotherDay === 'real') {
        todayEnd.setDate(todayEnd.getDate() + 1);
      }

      todayStart.setHours(Number(startTime.split(':')[0]), Number(startTime.split(':')[1]));
      todayEnd.setHours(Number(endTime.split(':')[0]), Number(endTime.split(':')[1]));

      await create({
        title,
        description,
        latitude: String(latitude),
        longitude: String(longitude),
        location: locationTitle,
        startTime: todayStart,
        endTime: todayEnd,
        categoryId: Number(categoryId),
        file,
        scheduleDate: new Date(scheduleDate),
      });
      close();
      // try {
      //   const schedule = await api.post('schedules', {
      //     title,
      //     description,
      //     latitude: String(latitude),
      //     longitude: String(longitude),
      //     location: locationTitle,
      //     startTime: todayStart,
      //     endTime: todayEnd,
      //     scheduleDate,
      //   });
      //   toast('Evento cadastrado com sucesso!', { type: 'success' });
      //   handleIsOpen();
      // } catch (error) {
      //   console.log(error);
      //   toast('Não foi possível cadastrar o evento', { type: 'error' });
      // }
    }
  }

  function close(): void {
    setErrorTitle('');
    setErrorPrecisionLocation('');
    setErrorEndTime('');
    setErrorDescription('');
    setErrorScheduleDate('');
    setErrorStartTime('');
    setErrorLocationTitle('');

    setTitle('');
    setLatitude('');
    setLongitude('');
    setEndTime('');
    setDescription('');
    setScheduleDate('');
    setStartTime('');
    setLocationTitle('');
    handleIsOpen();
  }

  function handleChange(v: string, f: string): void {
    setScheduleDate(v);
  }

  function handleState(point): void {
    setDefaultLocation({ lat: point.lat, lng: point.lng });
    setLatitude(point.lat);
    setLongitude(point.lng);
    setLocation(point);
  }

  function handleSameDay(): void {
    setSameDay(!sameDay);
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={close} size={'lg'}>
        <ModalHeader toggle={close}>Cadastro de evento</ModalHeader>
        <ModalBody>
          <Form>
            <div className="row">
              <div className="col-8">
                <FormGroup>
                  <Label
                    for="exampleEmail"
                    style={{
                      color: errorTitle?.length > 1 ? '#E44B39' : '#2C2C2D',
                    }}
                  >
                    Nome do evento
                  </Label>
                  <Input
                    style={{ borderColor: errorTitle?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                    id="exampleEmail"
                    name="email"
                    placeholder="Insira o nome do evento"
                    type="text"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />

                  <text
                    style={{
                      fontSize: 14,
                      color: '#E44B39',
                      marginBottom: 5,
                    }}
                  >
                    {errorTitle}
                  </text>
                </FormGroup>
              </div>

              <div className="col">
                <FormGroup>
                  <Label
                    style={{
                      color: errorCategoryId?.length > 1 ? '#E44B39' : '#2C2C2D',
                    }}
                    for="exampleEmail"
                  >
                    Categoria do evento
                  </Label>
                  <Input
                    style={{ borderColor: errorCategoryId?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                    type="select"
                    name="select"
                    id="exampleSelect"
                    value={
                      arrayCategories
                        ?.filter((item) => item.id === Number(categoryId))
                        .map((item) => item.id)[0]
                    }
                    onChange={(e) => {
                      setCategoryId(e.target.value);
                    }}
                  >
                    {arrayCategories.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Input>

                  <text
                    style={{
                      fontSize: 14,
                      color: '#E44B39',
                      marginBottom: 5,
                    }}
                  >
                    {errorCategoryId}
                  </text>
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <FormGroup>
                  <Label
                    style={{
                      color: errorScheduleDate?.length > 1 ? '#E44B39' : '#2C2C2D',
                    }}
                  >
                    Dia do evento início
                  </Label>

                  <ReactDatePicker
                    onChange={(e) => {
                      setScheduleDate(String(e));
                    }}
                    wrapperClassName={'date_picker full-width'}
                    popperClassName={'react-datepicker-popper'}
                    value={
                      scheduleDate
                        ? String(new Date(scheduleDate).toLocaleDateString())
                        : scheduleDate
                    }
                    minDate={new Date(2025, 7, 29)}
                    maxDate={new Date(2025, 8, 8)}
                    customInput={<Input />}
                    locale={'ptBR'}
                  />

                  <DatePickerWrapperStyles />
                  {/* <DatePicker
                // readOnly
                // onChangeRaw={(e) => e.preventDefault()}
                style={{ borderColor: errorScheduleDate?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                id="example-datepicker"
                // value   = {new Date()}
                showClearButton={false}
                // selected={new Date(2023, 8, 29)}
                // defaultValue={new Date(2023, 8, 29)}
                // value={scheduleDate}
                // onChange={(v, f) => {
                //   handleChange(v, f);
                // }}

                // onKeyDown={(e) => {
                //   e.preventDefault();
                // }}
                // minDate={new Date('2023')}
                editable={false}
                minDate={new Date(2023, 8, 29)}
                maxDate={new Date(2023, 9, 8)}
                placeholder=""
                monthLabels={[
                  'Janeiro',
                  'Fevereiro',
                  'Março',
                  'Abril',
                  'Maio',
                  'Junho',
                  'Julho',
                  'Agosto',
                  'Setembro',
                  'Outubro',
                  'Novembro',
                  'Dezembro',
                ]}
                dayLabels={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']}
                // onChange= {(v,f) => handleChange(v, f)}
              >
                <Input readOnly></Input>
              </DatePicker> */}
                  <text
                    style={{
                      fontSize: 14,
                      color: '#E44B39',
                      marginBottom: 5,
                    }}
                  >
                    {errorScheduleDate}
                  </text>
                </FormGroup>
              </div>
              {sameDay ? (
                <div className="col">
                  <FormGroup>
                    <Label
                      style={{
                        color: errorScheduleDateEnd?.length > 1 ? '#E44B39' : '#2C2C2D',
                      }}
                    >
                      Dia do evento fim
                    </Label>

                    <ReactDatePicker
                      onChange={(e) => {
                        setScheduleDate(String(e));
                      }}
                      wrapperClassName={'date_picker full-width'}
                      popperClassName={'react-datepicker-popper'}
                      value={
                        scheduleDate
                          ? String(new Date(scheduleDate).toLocaleDateString())
                          : scheduleDate
                      }
                      disabled
                      minDate={new Date(scheduleDate) || new Date(2025, 7, 29)}
                      maxDate={new Date(2025, 8, 8)}
                      customInput={<Input />}
                      locale={'ptBR'}
                    />

                    <DatePickerWrapperStyles />
                    {/* <DatePicker
                // readOnly
                // onChangeRaw={(e) => e.preventDefault()}
                style={{ borderColor: errorScheduleDate?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                id="example-datepicker"
                // value   = {new Date()}
                showClearButton={false}
                // selected={new Date(2023, 8, 29)}
                // defaultValue={new Date(2023, 8, 29)}
                // value={scheduleDate}
                // onChange={(v, f) => {
                //   handleChange(v, f);
                // }}

                // onKeyDown={(e) => {
                //   e.preventDefault();
                // }}
                // minDate={new Date('2023')}
                editable={false}
                minDate={new Date(2023, 8, 29)}
                maxDate={new Date(2023, 9, 8)}
                placeholder=""
                monthLabels={[
                  'Janeiro',
                  'Fevereiro',
                  'Março',
                  'Abril',
                  'Maio',
                  'Junho',
                  'Julho',
                  'Agosto',
                  'Setembro',
                  'Outubro',
                  'Novembro',
                  'Dezembro',
                ]}
                dayLabels={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']}
                // onChange= {(v,f) => handleChange(v, f)}
              >
                <Input readOnly></Input>
              </DatePicker> */}
                    <text
                      style={{
                        fontSize: 14,
                        color: '#E44B39',
                        marginBottom: 5,
                      }}
                    >
                      {errorScheduleDateEnd}
                    </text>
                  </FormGroup>
                </div>
              ) : (
                <div className="col">
                  <FormGroup>
                    <Label
                      style={{
                        color: errorScheduleDateEnd?.length > 1 ? '#E44B39' : '#2C2C2D',
                      }}
                    >
                      Dia do evento fim
                    </Label>

                    <ReactDatePicker
                      onChange={(e) => {
                        setScheduleDateEnd(String(e));
                      }}
                      wrapperClassName={'date_picker full-width'}
                      popperClassName={'react-datepicker-popper'}
                      value={
                        scheduleDateEnd
                          ? String(new Date(scheduleDateEnd).toLocaleDateString())
                          : scheduleDateEnd
                      }
                      minDate={new Date(scheduleDate) || new Date(2025, 7, 29)}
                      maxDate={new Date(2025, 8, 8)}
                      customInput={<Input />}
                      locale={'ptBR'}
                    />

                    <DatePickerWrapperStyles />
                    {/* <DatePicker
                // readOnly
                // onChangeRaw={(e) => e.preventDefault()}
                style={{ borderColor: errorScheduleDate?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                id="example-datepicker"
                // value   = {new Date()}
                showClearButton={false}
                // selected={new Date(2023, 8, 29)}
                // defaultValue={new Date(2023, 8, 29)}
                // value={scheduleDate}
                // onChange={(v, f) => {
                //   handleChange(v, f);
                // }}

                // onKeyDown={(e) => {
                //   e.preventDefault();
                // }}
                // minDate={new Date('2023')}
                editable={false}
                minDate={new Date(2023, 8, 29)}
                maxDate={new Date(2023, 9, 8)}
                placeholder=""
                monthLabels={[
                  'Janeiro',
                  'Fevereiro',
                  'Março',
                  'Abril',
                  'Maio',
                  'Junho',
                  'Julho',
                  'Agosto',
                  'Setembro',
                  'Outubro',
                  'Novembro',
                  'Dezembro',
                ]}
                dayLabels={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']}
                // onChange= {(v,f) => handleChange(v, f)}
              >
                <Input readOnly></Input>
              </DatePicker> */}
                    <text
                      style={{
                        fontSize: 14,
                        color: '#E44B39',
                        marginBottom: 5,
                      }}
                    >
                      {errorScheduleDateEnd}
                    </text>
                  </FormGroup>
                </div>
              )}
            </div>
            <FormGroup check style={{ marginBottom: 10 }}>
              <Label check>
                <Input checked={sameDay} onChange={handleSameDay} type="checkbox" /> Dia de inicio e
                dia de fim são iguais
              </Label>
            </FormGroup>
            {/* <FormGroup>
              <Label
                style={{
                  color: errorScheduleDate?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
              >
                Dia do evento
              </Label>

              <ReactDatePicker
                onChange={(e) => {
                  setScheduleDate(String(e));
                }}
                wrapperClassName={'date_picker full-width'}
                popperClassName={'react-datepicker-popper'}
                value={
                  scheduleDate ? String(new Date(scheduleDate).toLocaleDateString()) : scheduleDate
                }
                minDate={new Date(2023, 8, 29)}
                maxDate={new Date(2023, 9, 8)}
                customInput={<Input />}
                locale={'ptBR'}
              />

              <DatePickerWrapperStyles />
              
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorScheduleDate}
              </text>
            </FormGroup> */}

            <div className="row">
              <div className="col">
                <FormGroup>
                  <Label
                    style={{
                      color: errorStartTime?.length > 1 ? '#E44B39' : '#2C2C2D',
                    }}
                  >
                    Horário início
                  </Label>
                  <Input
                    style={{ borderColor: errorStartTime?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                    name="firstName"
                    placeholder="Insira o horário de início do evento"
                    type="time"
                    value={startTime}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                    }}
                  />
                  <text
                    style={{
                      fontSize: 14,
                      color: '#E44B39',
                      marginBottom: 5,
                    }}
                  >
                    {errorStartTime}
                  </text>
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup>
                  <Label
                    style={{
                      color: errorEndTime?.length > 1 ? '#E44B39' : '#2C2C2D',
                    }}
                  >
                    Horário fim
                  </Label>
                  <Input
                    style={{ borderColor: errorEndTime?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                    name="lastName"
                    placeholder="Insira o horário de início do evento"
                    type="time"
                    min={startTime}
                    value={endTime}
                    onChange={(e) => {
                      setEndTime(e.target.value);
                    }}
                  />
                  <text
                    style={{
                      fontSize: 14,
                      color: '#E44B39',
                      marginBottom: 5,
                    }}
                  >
                    {errorEndTime}
                  </text>
                </FormGroup>
              </div>
            </div>

            <FormGroup>
              <Label
                style={{
                  color: errorLocationTitle?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
                for="exampleEmail"
              >
                Local
              </Label>
              <Input
                style={{ borderColor: errorLocationTitle?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                id="exampleEmail"
                name="email"
                placeholder="Insira o nome do local do evento"
                type="text"
                value={locationTitle}
                onChange={(e) => {
                  setLocationTitle(e.target.value);
                }}
              />
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorLocationTitle}
              </text>
            </FormGroup>

            <FormGroup>
              <Label
                style={{
                  color: errorPrecisionLocation?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
                for="exampleEmail"
              >
                Mapa <b>(Clique no mapa para definir um local)</b>
              </Label>
              {/* <LocationPicker
                showInputs={false}
                startPort={teste}
                pointMode={pointMode}
                showControls={false}
                useDynamic={true}
                geoLayer={'/assets/icon.jpeg'}
                geoURL={'/assets/icon.jpeg'}
              /> */}
              <MapContainer
                // center={[38, 139.69222]}
                // zoom={6}
                // minZoom={3}
                // maxZoom={19}
                // maxBounds={[
                //   [-85.06, -180],
                //   [85.06, 180],
                // ]}
                // scrollWheelZoom={true}#2C2C2D
                bounds={[
                  [-0.042373, -51.11142],
                  [-0.042287, -51.103384],
                ]}
                style={{ width: '100%', height: 320 }}
              >
                <Map handleChange={handleState} />
                {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
              </MapContainer>
              {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorPrecisionLocation}
              </text>
              {/* <MapPicker defaultLocation={defaultLocation}
    //@ts-ignore
    mapTypeId={'roadmap'}
    zoom={zoom}
    style={{height:'700px'}}
    onChangeLocation={handleChangeLocation} 
    onChangeZoom={handleChangeZoom}
    apiKey='AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8'/> */}
            </FormGroup>

            <FormGroup>
              <Label
                style={{
                  color: errorDescription?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
                for="exampleEmail"
              >
                Descrição
              </Label>
              <Input
                value={description}
                onChange={(e) => {
                  setDescription(e?.target?.value);
                }}
                style={{ borderColor: errorDescription?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                id="exampleEmail"
                name="email"
                placeholder="Insira a descrição do evento"
                type="textarea"
              />
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorDescription}
              </text>
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: errorFile?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
                for="exampleEmail"
              >
                Imagem
              </Label>
              {file ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img width={'50%'} height={'auto'} src={URL.createObjectURL(file)} />
                  <br />
                  <label
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #0D6EFD',
                      width: 170,
                      height: 35,
                      borderRadius: 8,
                      marginRight: 20,
                      cursor: 'pointer',
                      color: '#052C65',
                    }}
                  >
                    <span>Substituir imagem</span>
                    <input
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      accept="image/png, image/gif, image/jpeg"
                      type="file"
                      style={{ display: 'none' }}
                    />
                  </label>
                </div>
              ) : (
                <Input
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                  accept="image/png, image/gif, image/jpeg"
                  style={{ borderColor: errorDescription?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                  id="exampleEmail"
                  name="email"
                  placeholder="Insira a descrição do evento"
                  type="file"
                />
              )}

              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorFile}
              </text>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              await registerEvent();
            }}
            style={{ minWidth: 120 }}
            disabled={loading}
          >
            {loading ? <Spinner type="border" size={'sm'} color="light" /> : 'Salvar evento'}
          </Button>{' '}
          <Button color="secondary" onClick={close}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
